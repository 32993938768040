<template>
  <v-row class="d-flex justify-center mt-0">
    <v-alert
        v-if="instructivos.length == 0 && !$store.state.loading"
        class="mt-8"
        type="warning"
        border="left"
        text
      >
        No hay instructivos disponibles
      </v-alert>
    <v-col cols="12" class="d-flex px-sm-8 px-4">
      <v-row class="d-flex justify-start">
        <v-col
            v-for="(card, index) in instructivos"
            :key="index"
            cols="6" sm="4" md="3" xl="2"
            class="d-flex justify-center mb-sm-4"
          >
          <v-hover v-slot="{ hover }">
            <v-card
              style="cursor: pointer"
              title="Descargar instructivo"
              target="_blank"
              :height="$vuetify.breakpoint.xs ? 150 : 200"
              :width="$vuetify.breakpoint.xl ? 275 : 250"
              :elevation="hover ? 12 : 2"
              :class="hover ? 'accent' : ''"
              :href="card.instructivo"
            >
              <v-card-text class="text-center">
                <div
                  class="mb-4"
                  :style="$vuetify.breakpoint.xs ? 'font-size: 16px' : 'font-size: 24px; margin-top: 16px'"
                  :class="hover ? 'white--text' : ''"
                >
                  <span v-html="card.nombre"></span>
                </div>
                <v-icon
                  :size="$vuetify.breakpoint.xs ? 50 : 80"
                  :color="hover ? 'white' : ''"
                >
                  {{ card.icono }}
                </v-icon>
              </v-card-text>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data () {
    return {
      instructivos: []
    }
  },
  async created () {
    this.$store.state.loading = true
    await this.$store.dispatch('genericos/get_instructivos')
      .then((res) => {
        this.instructivos = res.data
      })
      .catch(error => {
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      })
    this.$store.state.loading = false
  }
}
</script>